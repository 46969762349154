import Icon, { IconType } from '@atoms/Icon/Icon';
import SimpleLoader from '@molecules/Loader/SimpleLoader';
import { ButtonVariants } from '@type-declarations/cta';
import { StoreModifier } from '@type-declarations/modifier';
import clsx from 'clsx';
import { forwardRef } from 'react';

import styles from './Button.module.scss';
import WrapInButton from './WrapInButton';
import WrapInLink from './WrapInLink';

/**
 * Button inner
 */
interface ButtonInnerProps {
  hideLabel: boolean;
  iconLeft?: IconType;
  icon?: IconType | false | null;
  isRawLabel: boolean;
  label: string;
}

function ButtonInner({
  hideLabel,
  iconLeft,
  icon,
  isRawLabel,
  label,
}: ButtonInnerProps) {
  const labelClass = clsx(styles.label, hideLabel && 'u-visually-hidden');

  return (
    <span className={styles.inner}>
      {iconLeft && (
        <span
          className={clsx(styles.iconContainer, styles.iconContainerLeft)}
          aria-hidden="true"
        >
          <Icon className={styles.svgIcon} icon={iconLeft} />
        </span>
      )}

      {isRawLabel ? (
        <span
          className={labelClass}
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{ __html: label }}
        />
      ) : (
        <span className={labelClass}>{label}</span>
      )}

      {icon && (
        <span
          className={clsx(styles.iconContainer, styles.iconContainerRight)}
          aria-hidden="true"
        >
          <Icon className={styles.svgIcon} icon={icon} />
        </span>
      )}
    </span>
  );
}

/**
 * Button
 */
interface ButtonProps {
  label: string;
  className?: string;
  loading?: boolean;
  modifier?: 'redIcon' | 'filterActive' | StoreModifier | null;
  href?: string;
  icon?: IconType | null;
  iconLeft?: IconType;
  isRawLabel?: boolean;
  submit?: boolean;
  hideLabel?: boolean;
  fullWidth?: boolean;
  noAnimation?: boolean;
  count?: number;
  variant?: ButtonVariants;
  size?: 'small' | 'regular';
  onClick?: () => void;
  rounded?: boolean;
  target?: '_blank' | '_self';
  type?: 'button' | 'submit';
  form?: string;
  disabled?: boolean;
}

const Button = forwardRef<HTMLAnchorElement | HTMLButtonElement, ButtonProps>(
  (
    {
      label,
      className: extraClasses,
      loading,
      modifier,
      href,
      icon,
      iconLeft,
      count,
      isRawLabel = false,
      submit = false,
      hideLabel = false,
      fullWidth = false,
      variant = 'primary',
      size = 'regular',
      noAnimation = false,
      rounded = false,
      ...attributes
    }: ButtonProps,
    ref
  ) => {
    const className = clsx(
      styles.button,
      styles[variant],
      styles[size],
      extraClasses,
      fullWidth && styles.fullWidth,
      rounded && styles.rounded,
      hideLabel && styles.hideLabel,
      noAnimation && styles.noAnimation,
      loading && styles.loading,
      modifier && styles[modifier]
    );
    const title = hideLabel ? label : undefined;
    const childButtonAttrs = {
      hideLabel,
      iconLeft,
      icon,
      isRawLabel,
      label,
      loading,
    };

    if (href) {
      return (
        <WrapInLink
          // @ts-expect-error TODO: Ref can be of type HTMLAnchorElement of HTMLButtonElement
          ref={ref}
          href={href}
          className={className}
          title={title}
          attributes={attributes}
        >
          {/* eslint-disable-next-line react/jsx-props-no-spreading */}
          <ButtonInner {...childButtonAttrs} />
          {!!count && (
            <span className={styles.count} aria-hidden>
              {count}
            </span>
          )}
        </WrapInLink>
      );
    }

    return (
      <WrapInButton
        // @ts-expect-error TODO: Ref can be of type HTMLAnchorElement of HTMLButtonElement
        ref={ref}
        className={className}
        title={title}
        submit={submit}
        attributes={attributes}
      >
        {/* eslint-disable-next-line react/jsx-props-no-spreading */}
        <ButtonInner {...childButtonAttrs} />
        {!!count && (
          <span className={styles.count} aria-hidden>
            {count}
          </span>
        )}

        {loading && <SimpleLoader size={20} className={styles.loader} />}
      </WrapInButton>
    );
  }
);

Button.displayName = 'Button';

export default Button;
