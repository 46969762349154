import Icon from '@atoms/Icon/Icon';
import { BOOKMARK_COOKIE, BOOKMARK_ID_COOKIE } from '@constants/constants';
import useStoreBookmarks from '@hooks/useStoreBookmarks';
import useTranslations from '@hooks/useTranslations';
import { StoreModifier } from '@type-declarations/modifier';
import { PreprPage } from '@type-declarations/prepr';
import { getCookie, setCookie } from '@utils/clientCookies';
import clsx from 'clsx';
import { MouseEvent } from 'react';

import styles from './SaveButton.module.scss';

interface Props {
  id: string;
  pageTitle: string;
  pageType?: PreprPage;
  className?: string;
  variant?: 'gray' | 'brand';
  addLabel?: string;
  removeLabel?: string;
  hideLabel?: boolean;
  modifier?: StoreModifier;
}

export default function SaveButton({
  className,
  id,
  variant = 'gray',
  addLabel,
  removeLabel,
  hideLabel = true,
  modifier,
  pageTitle,
  pageType,
}: Props) {
  const { setBookmarks, bookmarks } = useStoreBookmarks();

  const t = useTranslations();

  const toggleSave = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    e.stopPropagation();

    const alreadySaved = bookmarks?.includes(id);

    let bookmarkId: string = getCookie(BOOKMARK_ID_COOKIE);
    if (!bookmarkId) {
      bookmarkId = Math.floor(Math.random() * Date.now()).toString();
    }

    if (!alreadySaved) {
      // Add the current id to the bookmarks array
      const bm = [...bookmarks, id];
      setCookie(BOOKMARK_COOKIE, JSON.stringify(bm));
      setBookmarks({ bookmarks: bm });
    } else {
      // Remove the current id from the bookmarks array
      const bm = bookmarks.filter((bookmark: string) => bookmark !== id);
      setCookie(BOOKMARK_COOKIE, JSON.stringify(bm));
      setBookmarks({ bookmarks: bm });
    }

    setCookie(BOOKMARK_ID_COOKIE, bookmarkId);

    window.dataLayer?.push({
      event: 'bookmark',
      bookmarkAction: alreadySaved ? 'remove' : 'add',
      bookmarkSession: bookmarkId,
      pageId: id,
      pageTitle,
      pageType,
    });
  };

  const saved = bookmarks?.includes(id);
  const label = saved
    ? removeLabel || t.bookmarkRemove
    : addLabel || t.bookmarkAdd;

  return (
    <button
      type="button"
      title={label}
      aria-label={label}
      onClick={toggleSave}
      className={clsx(
        styles.saveButton,
        hideLabel && styles.hideLabel,
        modifier && styles[modifier],
        styles[variant],
        saved && styles.saved,
        className
      )}
    >
      <div className={styles.iconContainer}>
        <Icon
          icon={saved ? 'BOOKMARK_SOLID' : 'BOOKMARK'}
          className={styles.icon}
        />
        <Icon icon="CHECK" className={styles.check} strokeWidth={4} />
      </div>

      {!hideLabel && label}
    </button>
  );
}
