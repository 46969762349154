import Icon, { IconType } from '@atoms/Icon/Icon';
import NavigationItem from '@atoms/NavigationItem/NavigationItem';
import useStore from '@hooks/useStore';
import FooterLogo from '@img/footer-logo.png';
import NewsletterSignup from '@molecules/Newsletter/NewsletterSignup';
import capitalizeFirstLetter from '@utils/capitalizeFirstLetter';
import clsx from 'clsx';
import Image from 'next/image';
import { useState } from 'react';

import styles from './Footer.module.scss';
import FooterNavigation from './FooterNavigation';

function Footer() {
  const {
    store: { config },
  } = useStore();

  const [openPanel, setOpenPanel] = useState<string | null>(null);

  if (!config?.footer) return null;

  return (
    <footer className={styles.footer}>
      <div className={styles.topBar}>
        <div className={clsx(styles.column, styles.navigationColumn)}>
          <FooterNavigation
            id="first"
            title={config.footer.firstColumnTitle}
            titleClass={styles.title}
            items={config.footer.firstColumnItems}
            expanded={openPanel === 'first'}
            onClick={id => setOpenPanel(openPanel === id ? null : id)}
          />
        </div>

        <div
          className={clsx(
            styles.column,
            styles.navigationColumn,
            openPanel === 'second' && styles.navigationColumnOpen
          )}
        >
          <FooterNavigation
            id="second"
            title={config.footer.secondColumnTitle}
            titleClass={styles.title}
            items={config.footer.secondColumnItems}
            expanded={openPanel === 'second'}
            onClick={id => setOpenPanel(openPanel === id ? null : id)}
          />
        </div>

        <div
          className={clsx(
            styles.column,
            styles.navigationColumn,
            openPanel === 'third' && styles.navigationColumnOpen
          )}
        >
          <FooterNavigation
            id="third"
            title={config.footer.thirdColumnTitle}
            titleClass={styles.title}
            items={config.footer.thirdColumnItems}
            expanded={openPanel === 'third'}
            onClick={id => setOpenPanel(openPanel === id ? null : id)}
          />
        </div>

        <div className={clsx(styles.column, styles.newsletterColumn)}>
          <NewsletterSignup
            title={config.footer.newsletterTitle}
            text={config.footer.newsletterText}
            className={styles.newsletter}
          />
        </div>

        <div className={styles.column}>
          <p className={clsx(styles.title, styles.socialsTitle)}>
            {config.footer.socialsTitle}
          </p>
          <div className={styles.socialsWrapper}>
            {config.footer.socials.map(social =>
              social.type ? (
                <a
                  key={social.id}
                  href={social.link}
                  target="_blank"
                  rel="noopener"
                  itemProp="url"
                  title={capitalizeFirstLetter(social.type)}
                  aria-label={capitalizeFirstLetter(social.type)}
                  className={styles.socialsIcon}
                >
                  <Icon icon={social.type.toUpperCase() as IconType} />
                </a>
              ) : null
            )}
          </div>
        </div>
      </div>

      <div className={styles.bottomBar}>
        <div className={styles.bottomBarDesktopContainer}>
          <ul className={clsx(styles.bottomLinksWrapper, 'u-list-clean')}>
            {config.footer.bottomLinks.map(link => (
              <li key={link.id} className={styles.bottomLink}>
                <NavigationItem item={link} />
              </li>
            ))}
          </ul>
          <p className={styles.slogan}>{config.footer.slogan}</p>

          <Image className={styles.logo} src={FooterLogo} alt="" />
        </div>
      </div>
    </footer>
  );
}

export default Footer;
