import Icon, { IconType } from '@atoms/Icon/Icon';
import { routeChangeStart } from '@atoms/NProgress/NProgress';
import { BOOKMARK_COOKIE, PANEL_IDS, ROUTES } from '@constants/constants';
import useFocusTrap from '@hooks/useFocusTrap';
import useStore from '@hooks/useStore';
import useStoreBookmarks from '@hooks/useStoreBookmarks';
import { MenuState } from '@type-declarations/menu';
import { getCookie } from '@utils/clientCookies';
import clsx from 'clsx';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useEffect, useRef, useState } from 'react';

import MainPanel from './MainPanel';
import styles from './SidePanel.module.scss';

const translations = {
  openMenu: {
    nl: 'Open menu',
    en: 'Open menu',
  },
  closeMenu: {
    nl: 'Sluit menu',
    en: 'Close menu',
  },
  main: {
    nl: 'Menu',
    en: 'Menu',
  },
  list: {
    nl: 'Mijn lijst',
    en: 'My list',
  },
  explore: {
    nl: 'Kaart',
    en: 'Map',
  },
  search: {
    nl: 'Zoeken',
    en: 'Search',
  },
};

const options = [
  {
    key: 'list',
    icon: 'BOOKMARK',
  },
  {
    key: 'map',
    icon: 'MAP',
  },
  {
    key: 'search',
    icon: 'SEARCH',
  },
] as { key: MenuState; icon: IconType }[];

interface Props {
  inView: boolean;
  canExpand: boolean;
}

export default function SidePanel({ inView, canExpand }: Props) {
  const [menuState, setMenuState] = useState<MenuState>(false);

  const ref = useRef<HTMLDivElement>(null);
  const toggleRef = useRef<HTMLButtonElement>(null);

  const { enableFocusTrap, disableFocusTrap } = useFocusTrap(ref, toggleRef);
  const { asPath } = useRouter();
  const { setBookmarks, bookmarks } = useStoreBookmarks();
  const {
    store: { locale },
  } = useStore();

  const toggleMenu = (value: MenuState): void => {
    setMenuState(curVal => (curVal === value ? false : value));
  };

  useEffect(() => {
    if (menuState) {
      toggleMenu(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [asPath, locale]);

  useEffect(() => {
    if (menuState) {
      enableFocusTrap();
    } else {
      disableFocusTrap();
    }

    return () => {
      disableFocusTrap();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [menuState]);

  useEffect(() => {
    const bm = JSON.parse(getCookie(BOOKMARK_COOKIE) || '[]');
    setBookmarks({ bookmarks: bm });
  }, [setBookmarks]);

  const closePanel = () => {
    setMenuState(false);
  };

  const toggleLabel =
    menuState === 'main'
      ? translations.closeMenu[locale]
      : translations.openMenu[locale];

  return (
    <div ref={ref}>
      <div
        className={clsx(
          styles.sidePanel,
          !!menuState && styles.menuOpen,
          canExpand && inView && styles.inView
        )}
      >
        {/* Menu toggle */}
        <button
          ref={toggleRef}
          className={clsx(
            styles.panelItem,
            menuState === 'main' && styles.active
          )}
          type="button"
          onClick={() => toggleMenu('main')}
          aria-label={toggleLabel}
          aria-expanded={menuState === 'main' ? 'true' : 'false'}
          aria-controls={PANEL_IDS.main}
        >
          <Icon icon="MENU" className={styles.icon} />
          <span>{translations.main[locale]}</span>
        </button>

        {/* Collapsible list */}
        <ul className={clsx(styles.list, 'u-list-clean')}>
          {options.map(({ key, icon }) => {
            if (!key) return null;

            if (key === 'map') {
              return (
                <li key={key}>
                  <Link
                    href={`/${locale}/${ROUTES.MapPage[locale]}`}
                    className={styles.panelItem}
                    onClick={routeChangeStart}
                  >
                    <span className={styles.iconContainer}>
                      <Icon icon={icon} className={styles.icon} />
                    </span>
                    <span>{translations.explore[locale]}</span>
                  </Link>
                </li>
              );
            }

            return (
              <li key={key}>
                <button
                  type="button"
                  className={clsx(
                    styles.panelItem,
                    menuState === key && styles.active
                  )}
                  onClick={() => toggleMenu(key)}
                  aria-expanded={menuState === key ? 'true' : 'false'}
                  aria-controls={PANEL_IDS[key]}
                >
                  <span className={styles.iconContainer}>
                    <Icon icon={icon} className={styles.icon} />
                    {key === 'list' && bookmarks?.length > 0 && (
                      <span className={styles.counter}>{bookmarks.length}</span>
                    )}
                  </span>
                  <span>{translations[key][locale]}</span>
                </button>
              </li>
            );
          })}
        </ul>
      </div>

      <MainPanel menuState={menuState} closePanel={closePanel} />
    </div>
  );
}
