import NavigationItem from '@atoms/NavigationItem/NavigationItem';
import { routeChangeStart } from '@atoms/NProgress/NProgress';
import useStore from '@hooks/useStore';
import IconLogo from '@svg/logo.svg';
import { HeroVariant } from '@type-declarations/hero';
import clsx from 'clsx';
import Link from 'next/link';
import { useInView } from 'react-intersection-observer';

import styles from './Header.module.scss';
import SidePanel from './SidePanel';

interface Props {
  heroVariant: HeroVariant | null;
}

export default function Header({ heroVariant }: Props) {
  const { ref, inView } = useInView({
    delay: 0,
  });

  const {
    store: { config: { mainMenu } = {}, page },
  } = useStore();

  const topLevelLinks = mainMenu?.filter(
    ({ internalLink, externalLink }) =>
      (internalLink && internalLink[0]) || externalLink
  );

  return (
    <header
      className={clsx(
        styles.header,
        heroVariant && styles.hasHero,
        heroVariant === 'large' && styles.hasLargeHero,
        (!heroVariant || !page?.image) && styles.solid,
        heroVariant && !page?.image && styles.hasHeroWithoutImage
      )}
    >
      {/* Static TOP-BAR with logo en additional menu-items */}
      <div className={styles.topBar}>
        <div ref={ref} className={styles.inViewRef} />
        <div className={clsx(styles.container, 'u-container-width')}>
          <Link
            href="/"
            className={styles.logoLink}
            aria-label="Home"
            onClick={routeChangeStart}
          >
            <IconLogo className={styles.logo} />
          </Link>

          {/* Some menu-items here (<ul>) */}
          {!!topLevelLinks?.length && (
            <ul className={clsx(styles.list, 'u-list-clean')}>
              {topLevelLinks.map(menuItem => (
                <li key={menuItem.id}>
                  <NavigationItem item={menuItem} className={styles.link} />
                </li>
              ))}
            </ul>
          )}
        </div>
      </div>

      {/* Fixed sidepanel (top-right) */}
      <SidePanel inView={inView} canExpand={!!heroVariant && !!page?.image} />
    </header>
  );
}
