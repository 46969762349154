import gql from 'graphql-tag';

import { localizationsFields } from './localizations';

export const categoryFields = `
  id: _id
  typename: __typename
  title
  slug: _slug
  ${localizationsFields}
`;

export const subCategoryQuery = `
  ${categoryFields}

  category {
    ${categoryFields}
    heroColor: hero_color {
      color
    }
    colorPalette: color_palette
  }
`;

export const subSubCategoryQuery = `
  ${categoryFields}

  category {
    ${categoryFields}

    category {
      ${categoryFields}
    }
  }
`;

export const menuItemFields = `
  typename: __typename
  id: _id
  title
  externalLink: external_link
  internalLink: internal_link {
    typename: __typename

    ... on Model {
      slug: _slug
    }
    
    ... on SubCategory {
      ${subCategoryQuery}
    }

    ... on SubSubCategory {
      ${subSubCategoryQuery}
    }
  }
`;

// The menu is 2 levels deep,
// if another level needs to be supported just add another level of children
const menuItemFragment = gql`
  fragment menuItemFieldsWithChildren on MenuItem {
    ${menuItemFields}
    children {
      ${menuItemFields}
    }
  }
`;

export default menuItemFragment;
